/* global document grecaptcha */
const React = require('react');
const PropTypes = require('prop-types');

const Form = require('@andes/form');
const TextField = require('@andes/textfield');
const { Button } = require('@andes/button');
const Snackbar = require('@andes/snackbar');
const { ProgressIndicatorCircular } = require('@andes/progress-indicator-circular');
const Recaptcha = require('../recapcha');

const ContactHelper = require('./helper');
const ClientService = require('../../../services/client');
const metadataPropType = require('../metadataProvider/metadataPropType');

class ContactForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.siteKey = this.context.metadata.siteKey;
    this.state = {
      performingRequest: false,
      email: '',
      message: '',
      mailError: '',
      mailState: 'default',
      messageError: '',
      recaptchaError: 'hidden',
      feedbackMessage: '',
      snackBarType: 'success',
      showSnackBar: false,
      snackBarClass: 'hidden',
    };
    this.validate = {
      email: ContactHelper.validateEmail,
      message: ContactHelper.validateMessage,
      recaptcha: ContactHelper.validateCaptcha,
    };
    this.restClient = new ClientService(this.context.metadata.restClient.baseURL);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  getUserData() {
    const data = {
      email: this.state.email,
      message: this.state.message,
    };
    const recaptcha = document.getElementById('g-recaptcha-response');
    if (recaptcha) {
      data['g-recaptcha-response'] = recaptcha.value;
    }
    return data;
  }

  showSnackbar(result) {
    return {
      feedbackMessage: this.props.feedback[result],
      snackBarType: result,
      showSnackBar: true,
      snackBarClass: '',
    };
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    }, () => {
      this.setState(
        this.validate[name](this.state[name]).state,
      );
    });
  }

  validateForm() {
    const recaptcha = document.getElementById('g-recaptcha-response');

    const emailResult = this.validate.email(this.state.email, this.props.fields['e-mail'].validations);
    const messageResult = this.validate.message(this.state.message, this.props.fields.message.validations);
    const captchaResult = this.validate.recaptcha(recaptcha);

    const result = {
      success: emailResult.success && messageResult.success && captchaResult.success,
      state: { ...emailResult.state, ...messageResult.state, ...captchaResult.state },
    };

    this.setState(result.state);
    return result.success;
  }

  async sendMessage() {
    const result = {
      status: 'error',
      state: {
        performingRequest: false,
      },
    };

    this.setState({ performingRequest: true });

    return this.restClient
      .post(this.props.submit.endpoint, this.getUserData())
      .then(() => {
        result.status = 'success';
        result.state = {
          ...this.state,
          email: '',
          message: '',
          mailState: 'default',
          performingRequest: false,
        };
        grecaptcha.reset();
      }).catch(() => {
        result.status = 'error';
      }).finally(() => {
        this.setState({
          ...result.state,
          ...this.showSnackbar(result.status),
        });
      });
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.validateForm()) {
      this.sendMessage();
    }
  }

  handleLanguage() {
    const { recaptcha: { country } } = this.props;
    return country === 'BR' ? 'pt' : 'es';
  }

  render() {
    return (
      <div>
        <Form
          className="contact-form"
          onSubmit={this.handleSubmit}
          noValidate
        >
          <h2 className="contact-form__title">{this.props.title}</h2>
          <TextField
            className="contact-form__input contact-form__input--email"
            label={`${this.props.fields['e-mail'].label}*`}
            name="email"
            onChange={this.handleInputChange}
            modifier={this.state.mailState}
            message={this.state.mailError}
            value={this.state.email}
            required
          />
          <TextField
            className="contact-form__input"
            label={`${this.props.fields.message.label}*`}
            name="message"
            maxLength={this.props.fields.message.validations.max_length.length}
            onChange={this.handleInputChange}
            multiline
            countdown
            modifier={this.state.messageError ? 'error' : 'default'}
            message={this.state.messageError}
            value={this.state.message}
            required
          />
          <Recaptcha
            siteKey={this.siteKey}
            action="SEND_MESSAGE"
            lang={this.handleLanguage()}
          />
          <div className={`contact-form__recaptcha-validation ${this.state.recaptchaError}`}>
            {this.props.recaptcha.validations.require}
          </div>
          <Button type="submit">{this.props.submit.label}</Button>

          <ProgressIndicatorCircular
            className="contact-form__spinner"
            label={this.props.submit.spinner_label}
            modifier="block"
            size="large"
            show={this.state.performingRequest}
          />
        </Form>

        <Snackbar
          className={this.state.snackBarClass}
          message={this.state.feedbackMessage}
          type={this.state.snackBarType}
          show={this.state.showSnackBar}
          delay={3000}
        />
      </div>
    );
  }
}

ContactForm.propTypes = {
  title: PropTypes.string.isRequired,
  fields: PropTypes.shape({
    'e-mail': PropTypes.shape({
      label: PropTypes.string,
      validations: PropTypes.shape({
        valid: PropTypes.string,
        require: PropTypes.string,
      }),
    }),
    message: PropTypes.shape({
      label: PropTypes.string,
      validations: PropTypes.shape({
        require: PropTypes.string,
        max_length: PropTypes.shape({
          message: PropTypes.string,
          length: PropTypes.number,
        }),
      }),
    }),
  }).isRequired,
  recaptcha: PropTypes.shape({
    country: PropTypes.string,
    validations: PropTypes.shape({
      require: PropTypes.string,
    }),
  }).isRequired,
  submit: PropTypes.shape({
    label: PropTypes.string,
    spinner_label: PropTypes.string,
    endpoint: PropTypes.string,
  }).isRequired,
  feedback: PropTypes.shape({
    success: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
};

ContactForm.contextTypes = {
  metadata: metadataPropType.isRequired,
};

module.exports = ContactForm;
