require('babel-polyfill');
const React = require('react');

const PropTypes = require('prop-types');
const Head = require('nordic/head');
const serialize = require('serialize-javascript');

const injectI18n = require('nordic/i18n/injectI18n');
const I18nProvider = require('nordic/i18n/I18nProvider');
const MeliGA = require('nordic/analytics/meli-ga');
const MelidataTrack = require('nordic/melidata/melidata-track');
const Script = require('nordic/script');
const Style = require('nordic/style');
const Appearance = require('@mshops-web-core/appearance').default;

const canUseDOM = require('../../../utils/enabledDOM');

const HomeLayout = require('./HomeLayout');
const MetadataProvider = require('../../../components/metadataProvider');
const RenderTree = require('../../../appearance/RenderTree');
const componentsList = require('./nonEditables');

const { RenderProvider } = require('../context');

const { HOME } = require('../../../utils/constants/sections');
const { EDITABLE_MODE } = require('../../../utils/constants/common');

const { useEffect } = React;

function HomeView({
  i18n,
  translations,
  site,
  siteId,
  lowEnd,
  deviceType,
  company,
  shopModel,
  config,
  editable,
  section,
  allowMetrics,
  siteKey,
  apiBasePath,
  showModalSubscription,
  isHubPreview,
  manifest,
  assetsPrefix,
  uuidVersion,
  locale,
  device,
}) {
  // eslint-disable-next-line
  const { appearance, fiscal_data, shop, storefront, contact } = shopModel;
  const { currency, title } = appearance.properties;
  const { restClient } = config;
  const ShopTypeId = storefront?.id ?? shop.id;

  const appearanceService = new Appearance(appearance);
  const layoutTheme = appearanceService.getComponentProperty('Document', 'theme');
  const metaTags = appearanceService.getComponentProperty('Document', 'meta_tags');

  const serializableProps = {
    translations,
    site,
    siteId,
    lowEnd,
    deviceType,
    company,
    shopModel,
    config,
    editable,
    section,
    allowMetrics,
    siteKey,
    apiBasePath,
    showModalSubscription,
    isHubPreview,
    manifest,
    assetsPrefix,
    uuidVersion,
    locale,
    device,
  };

  const customDimensions = { collectorId: ShopTypeId };
  const page = (section === HOME) ? 'MAIN' : section.toUpperCase();

  useEffect(() => {
    if (canUseDOM() && window.tracking_mediator) {
      window.tracking_mediator(
        'dispatch',
        `page:${window.__PRELOADED_STATE__.section}`,
        window.__PRELOADED_STATE__.shopModel.appearance,
      );
      window.tracking_mediator(
        'dispatch',
        `gtm:${window.__PRELOADED_STATE__.section}`,
        { page, section },
      );
    }
  }, []);

  const renderProviderInitialValue = {
    theme: layoutTheme,
    fiscalData: fiscal_data,
    contact,
    socialNetworks: shop?.social_networks,
    appearance: new Appearance(appearance),
    editableMode: uuidVersion ? EDITABLE_MODE.LAYOUT : EDITABLE_MODE.COMPONENTS,
    initial: new Appearance(appearance),
    device: deviceType,
    apiBasePath,
    deviceType,
    draftMode: !!(uuidVersion),
  };

  return (
    <>
      {allowMetrics
        && [
          <MeliGA
            siteId={siteId}
            platform={site}
            section="HOME"
            business="SHOPS"
            page={`/${page}/`}
            dimensions={customDimensions}
          />,
          <MelidataTrack
            path="/home"
          />,
        ]}

      <Head>
        <title>{title}</title>
        {(metaTags && metaTags.length)
          ? metaTags.map((meta) => <meta key={meta.type} name={meta.type} content={meta.content} />)
          : null
        }
      </Head>
      <Style href={`${layoutTheme}/home-${layoutTheme}-${deviceType}.css`} />
      <Script>
        {`
          window.__PRELOADED_STATE__ = ${serialize(serializableProps, { isJSON: true }) || {}};
        `}
      </Script>
      <Script src="https://cdn.polyfill.io/v3/polyfill.min.js?features=es5,es6&flags=gated" />
      <Script src="home.js" />
      <Script src="@frontend-performance/image-lazy-loading/src.js" on="now" inline />

      <I18nProvider i18n={i18n}>
        <MetadataProvider
          metadata={{
            currency,
            deviceType,
            siteId,
            lowEnd,
            siteKey,
            restClient,
            fiscalData: fiscal_data,
            contact,
            socialNetworks: shop?.social_networks,
            layoutTheme,
            shopModel,
            showModalSubscription,
            isHubPreview,
          }}
        >
          <RenderProvider value={renderProviderInitialValue}>
            <HomeLayout
              section={section}
              isCustomHTML={shopModel?.appearance?.properties?.custom}
              shopName={shopModel?.shop?.name}
            >
              <RenderTree componentsList={componentsList} i18n={i18n} />
            </HomeLayout>
          </RenderProvider>
        </MetadataProvider>
      </I18nProvider>
    </>
  );
}

HomeView.propTypes = {
  i18n: PropTypes.shape().isRequired,
  translations: PropTypes.shape(),
  site: PropTypes.string,
  siteId: PropTypes.string.isRequired,
  lowEnd: PropTypes.bool.isRequired,
  deviceType: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  shopModel: PropTypes.shape(),
  config: PropTypes.shape().isRequired,
  editable: PropTypes.bool,
  section: PropTypes.string.isRequired,
  allowMetrics: PropTypes.bool.isRequired,
  siteKey: PropTypes.string.isRequired,
  apiBasePath: PropTypes.string.isRequired,
  showModalSubscription: PropTypes.bool.isRequired,
  isHubPreview: PropTypes.bool.isRequired,
  manifest: PropTypes.shape(),
  assetsPrefix: PropTypes.string,
  uuidVersion: PropTypes.string,
  locale: PropTypes.string.isRequired,
  device: PropTypes.shape().isRequired,
};

HomeView.defaultProps = {
  translations: {},
  site: 'MS',
  shopModel: {
    appearance: {
      properties: {},
    },
  },
  editable: false,
  manifest: {},
  assetsPrefix: '',
  uuidVersion: '',
};

module.exports = injectI18n((HomeView));
